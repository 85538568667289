#ubicacion{
    min-height: 550px;
    width: 100%;
    position: relative;
    .embed-responsive-vt{
        width: 100%;
        height: 400px;
        .embed-responsive-item-vt{
            width: 100%;
            height: 100%;
            border:0;
        }
    }
    
    
    
}