#masterplan{
    .embed-responsive-vt{
        width: 100%;
        height: 600px;
       .box{
            position: relative;
            height: 600px;
            display: flex;
            justify-content: center;
            align-items: center;  

            .centrado{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
            }
            .transparencia{
                transition: all ease 0.5s;
                background-color: gray;
                opacity: 0.5;
                width: 100%;
                height: 100%;
                z-index: 2; 
            }
            .texto{
                transition: all ease 0.5s;
                padding: 0.25em;
                font-size: 1.25em;
                background-color: rgba(0,0,0,0.85);
                border: 0;
                color: white;
                z-index: 5;	
            }
            &:hover{
                .transparencia{
                    opacity: 0;
                    z-index: -1;
                }
                .texto{
                    opacity: 0;
                    z-index: -1;
                }

            }

        }
        
        .embed-responsive-item-vt{
            width: 100%;
            height: 100%;
            border:0;
            z-index: 1;
        }
    }
    
}