
.header-3{
    .content-center{
        top:70%;
        .title{
            font-size: 2em;
        }
        .contenido{
            font-size: 1.5em;
        }

    }
}
@media(max-width:767px){
    .header-3{
        .content-center{
            top:80%;
            .title{
                font-size: 1.5em;
            }
            .contenido{
                font-size: 1em;
            }

        }
    }
}
    