
.text-hand-writing{
    font-family: 'Parisienne', cursive;
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;

}
.text-p{
    font-size: 1.0em;
    text-align: center;
    margin-bottom: 0.75em;
}
.text-normal{
    font-size: 1.25em;
    line-height: 1.5;
    color:black;
    text-align: justify;
}
.title-proyecto{
    padding-bottom:15px;
    font-size: 1.25rem;
    font-family: "Merriweather", sans-serif;
}
.subtitle-proyecto{
    font-size: 0.85rem;
    color:black;
}
.parrafo-proyecto{
    font-size: 1rem;
    text-align: justify;
}
.bg{
    background-color: lightslategray;
}
.white{
    color:white;
}
@media(max-width:767px){
    
}